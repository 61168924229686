//import vue router
import { createRouter, createWebHistory } from 'vue-router';

//import axios
import axios from 'axios';

//default base URL / EndPoint API
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.baseURL = "https://admin.alazhartasikmalaya.id/";


//define a routes
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import( /* webpackChunkName: "Home" */ "@/views/home/Index"),
    },
    {
        path: '/post',
        name: 'post',
        component: () => import( /* webpackChunkName: "PostIndex" */ "@/views/post/Index"),
    },
    {
        path: '/:slug',
        name: 'detail_post',
        component: () => import( /* webpackChunkName: "PostShow" */ "@/views/post/Show")
    },
    {
        path: '/profil',
        name: 'profil',
        component: () => import( /* webpackChunkName: "ProfilIndex" */ "@/views/profil/Index"),
    },
    {
        path: '/:slug',
        name: 'detail_profil',
        component: () => import( /* webpackChunkName: "ProfilShow" */ "@/views/profil/Show")
    },
    {
        path: '/infopmb',
        name: 'infopmb',
        component: () => import( /* webpackChunkName: "InfopmbIndex" */ "@/views/infopmb/Index"),
    },
    {
        path: '/:slug',
        name: 'detail_infopmb',
        component: () => import( /* webpackChunkName: "InfopmbShow" */ "@/views/infopmb/Show")
    },
    {
        path: '/tag/:slug',
        name: 'detail_tag',
        component: () => import( /* webpackChunkName: "TagShow" */ "@/views/tag/Show")
    },
    {
        path: '/category/:slug',
        name: 'detail_category',
        component: () => import( /* webpackChunkName: "CategoryShow" */ "@/views/category/Show")
    },
    {
        path: '/event',
        name: 'event',
        component: () => import( /* webpackChunkName: "EventIndex" */ "@/views/event/Index")
    },
    {
        path: '/event/:slug',
        name: 'detail_event',
        component: () => import( /* webpackChunkName: "EventShow" */ "@/views/event/Show")
    },
    {
        path: '/photo',
        name: 'photo',
        component: () => import( /* webpackChunkName: "PhotoIndex" */ "@/views/photo/Index")
    },
    {
        path: '/video',
        name: 'video',
        component: () => import( /* webpackChunkName: "VideoIndex" */ "@/views/video/Index")
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import( /* webpackChunkName: "ContactIndex" */ "@/views/contact/Index")
    },
    
    
]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes // <-- routes
})

export default router